<template>
    <div id="satu" class="service-detail">
        <img src="../assets/images/Asset 7695.png" width="100%" alt="">
        <div class="container-fluid ">
            <div class="container text-start my-5">
                <p>3D Computer Graphics (in contrast to 2D computer graphics) are graphics that use a three-dimensional representation of geometric data (often cartesian) that is stored in the computer for the purposes of performing calculations and rendering 2D images. Such images may be stored for viewing later or displayed in real-time. 3D computer graphics rely on many of the same algorithms as 2D computer vector graphics in the wire frame models and 2D computer raster graphics in the final rendered display.</p><br>
                <p>3D computer graphics are often referred to as 3D models. 3D animation is one of the hot field in these days and lots of work is done in 3D animation, lots of cartoon is created in 3D format. Polargate Studio is also deal in this field. Our aim is to provide best services to our client and make a health and long lasting relations with them. We always concentrate on providing the best to the client. We have a team and having an professional attitude towards our work.</p>
            <hr class="my-5 px-5">
            </div>
            <div class="container ">
                <div class="row my-5">
                    <div class="col-12">
                        <div class=" mb-3" style="width: 100%;">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6">
                            <img src="../assets/images/Asset 1300.png" width="100%" class="img-fluid" alt="...">
                            </div>
                            <div class="col-col-12 col-md-6 col-lg-6">
                            <div class="card-body mt-5 pt-5 text-end">
                                <h5 class="card-title">3D Video and Film</h5>
                                <p class="card-text">Among the types of 3D animation,  3D video and film is the most widely known.</p>
                                <p>A three-dimensional visual feature of moving images within a digital environment, it uses motion graphics to move the subjects around a 3D space. This type is purely visual, which means there’s no interaction required.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-12">
                        <div class="mb-3" style="width: 100%;">
                        <div class="row g-0">
                            <div class="col-12 col-md-6 col-lg-6">
                            <div class="card-body mt-5 pt-5 text-start">
                                <h5 class="card-title">Interactive 3D</h5>
                                <p class="card-text">This is a 3D production that features purely digital environment created in using 3D software. While 3D video and film animation relies on the visual experience, this type involves user interaction. This allows users to be in first person and move within the 3D world through a computer screen, with mouse and keyboards as external controls.</p>
                            </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                            <img src="../assets/images/Asset 1300.png" width="100%" class="img-fluid rounded-start" alt="...">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-12">
                        <div class="mb-3" style="width: 100%;">
                        <div class="row g-0">
                            <div class="col-12 col-md-6 col-lg-6">
                            <img src="../assets/images/Asset 1300.png" width="100%" class="img-fluid rounded-start" alt="...">
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                            <div class="card-body mt-5 pt-5 text-end">
                                <h5 class="card-title">Virtual Reality 3D</h5>
                                <p class="card-text">Among the 3D animation types, virtual reality is the one that has opened an entirely new world for users. Like interactive 3D, this features purely digital environment created in a 3D software, but is used and experienced in an immersive way. Virtual reality 3D requires a device, such as Google Glass and Oculus, to allow its users to interact with the physical space. Of the types of 3D animation, virtual reality 3D is the most immersive and complex to create. </p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style scoped>
.service-detail{
   padding-top: 80px;
}

@media only screen and (max-width: 1000px) {
    .service-detail{
    padding-top: 82px;
    }
}
@media only screen and (max-width: 700px) {
    .service-detail{
    padding-top: 82px;
    }
}
</style>
